@use '../../styles/colors';

.input_field {
  width: 100%;
  height: 45px;
  position: relative;
  transition: 0.3s all;
  background-color: transparent;
  border-bottom: 0.5px solid colors.$Boston_Blue;
}
.input_field:hover {
  border-bottom: 0.55px solid colors.$Crusta;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.input_field_error {
  border-bottom: 0.55px solid colors.$carnation;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 13px;
}

.input_field input {
  width: 100%;
  height: 50px;
  position: relative;
  padding: 0px 0px;
  border: none;
  border-radius: 4px;
  font-family: "Quicksand";
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: colors.$Crusta;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
}
.input_field input::-webkit-input-placeholder {
  color: colors.$Boulder;
  font-weight: 300;
  font-size: 18px;
  padding-left: -5px;
}
.input_field input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.input_field input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.input_field input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: colors.$Crusta;
  -webkit-box-shadow: 0 0 0 0px white inset !important;
  box-shadow: 0 0 0 0px white inset !important;
}

.error_section {
  text-align: left;
  display: flex;
  align-items: center;
  color: colors.$carnation2;
  margin-top: 0px;

  .error_icon {
    height: 12px;
    margin-bottom: 3px;
  }

  .error_label {
    font-size: 15px;
    margin-left: 5px;
    font-family: "Quicksand";
    letter-spacing: 0;
    line-height: 15px;
  }
}

.update_input {
  display: flex;
  gap: 10px;
  padding: 8px;
  .label {
    font-size: 16px;
    color: colors.$black;
    width: 30%;
    word-wrap: break-word;
  }
  .value {
    border: 1px solid colors.$BoulderLight;
    border-radius: 3px;
    font-size: 16px;
    padding: 7px 10px;
    color: colors.$black;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    width: 70%;
  }
}
