@use '../styles/colors';
@import "~react-gif-player/src/GifPlayer";

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.display_section {
  display: flex;
  justify-content: center;
}

.display_section_full {
  display: flex;
}


.App-header {
  font-size: calc(10px + 1vmin);
  color: black;
  min-height: 30px;
}

.video_listing {
  font-size: calc(10px + 0.5vmin);
  color: colors.$Crusta;
  margin-left: 3em;
  margin-right: 3em;
  .heading {
    color: colors.$Crusta;
    font-size: 18px;
    font-weight: 800;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.upload_progress_row {
  padding-bottom: 1em;
  color: colors.$black;
}
.upload_row {
  padding-bottom: 1em;
  .upload_file {
    width: 15em;
  }
}

.header_row {
  padding-bottom: 2em;
  padding-top: 2em;
  font-weight: 800;
}

.multiple_delete_button {
  padding-bottom: 1.5em;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
}

.upload_dropdown {
  margin-top: 1em;
  justify-content: center;
  display: flex;
  background-color: colors.$Crusta;
  height: 35px;
  border-radius: 10px;
  align-items: center;
  margin-right: 20px;
  font-weight: 600;
  cursor: pointer;
  color: colors.$white;
  width: 220px;
}

.notification_btn {
  margin-top: 1em;
  justify-content: center;
  display: flex;
  background-color: colors.$Crusta;
  height: 35px;
  border-radius: 10px;
  align-items: center;
  margin-right: 20px;
  font-weight: 600;
  cursor: pointer;
  color: colors.$white;
  width: 200px;
}

.ant-btn-primary {
  background: colors.$Crusta;
  border-color: colors.$Crusta;
}

.upload_form_button {
  border-radius: 10px;
  font-weight: 600;
}

.upload_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 10px;

  .form_row {
    display: flex;
    justify-content: space-between;
    width: 500px;
    padding-bottom: 1em;
    font-weight: 800;
  }

  .form_input_file {
    width: 15em;
  }

  .form_input {
    width: 15em;
    min-height: 35px;
    color: colors.$Boston_Blue;
    border: none;
    box-shadow: 0px 0px 6px 0px #00000040;
    border-radius: 8px;
  }
}

.video_player_row {
  padding-bottom: 1em;
  justify-content: center;
  align-items: center;
}

.video_detail_row {
  padding-bottom: 2em;
  justify-content: space-between;
  align-items: center;
  height: 300px;
}

.media_details_img {
  margin-left: 20px;
}

.thumbnail {
  width: 230px;
}

.thmubnail-key {
  margin-top: 10px;
  font-size: 13px;
  color: colors.$Crusta;
}

.media_details_gif {
  height: 140px;
  width: 230px;
  margin-left: 20px;
}

.media_details {
  justify-content: center;
  width: 500px;
  text-align: center;

  .media_label {
    background-color: colors.$Crusta;
    color: white;
    border-radius: 3px;
    padding: 7px;
  }

  .detail_col {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 14px;
    color: colors.$Silver;
    margin-left: 2em;

    .detail_label {
      font-weight: bolder;
    }
  }
}

.preview {
  text-align: center;
  margin-top: 5em;
  font-weight: 500;
}

.reload_table {
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
}

.gif_player .play_button {
  padding: 10px 10px;
  font-size: 20px;
}

.login_section {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 500px;
  height: 100vh;

  .auth_btn {
    width: 280px;
  }

  .form_row {
    margin-bottom: 1em;
  }

  .form_row_login {
    margin-bottom: 2em;
  }

  .success_row {
    text-decoration: underline;
    cursor: pointer;
  }

  .heading_text {
    font-size: 3em;
    font-family: "Quicksand";
    font-weight: 400;
    color: colors.$Boston_Blue;

    @media (max-width: 576px) {
      font-size: 2.5em;
      line-height: 1em;
    }
  }

  .sub_text {
    font-size: 1.5em;
    font-family: "Quicksand";
    line-height: 1em;
    color: colors.$Boston_Blue;

    @media (max-width: 576px) {
      font-size: 1.1em;
      padding-top: 10px;
      padding-bottom: 0px;
    }
  }

  .first_form_row {
    padding-top: 2em;
  }

  .btn_section {
    text-align: center;
    margin-top: 3em;
    margin-bottom: 2em;
  }

  .link_section {
    text-decoration: underline;
    color: colors.$Boston_Blue;
    font-family: "Quicksand";
    font-size: 14px;

    &:hover {
      color: colors.$dodgerBlue;
    }
  }

  .login_first_row {
    margin-top: 2em;
    @media (max-width: 576px) {
      margin-top: 2em;
    }
  }

  .error_row {
    margin-top: 5px;
    text-align: left;
  }
}

.gradient_btn {
  background-color: colors.$Crusta;
  height: 50px;
  border: none;
  font-weight: 800 !important;
  border-radius: 10px;
  text-shadow: none;
  color: colors.$white !important;
  font-family: "Quicksand";
  font-size: 20px;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-color: colors.$Boston_Blue;
  }
}

.table-thumbnail {
  height: 130px;
  cursor: pointer;
}

.delete-icon {
  font-size: 1.2em;
  &:hover {
    color: colors.$carnation2;
  }
}

.delete-loader {
  font-size: 1.2em;
}

.subscriber_info_section {
  border: 1px solid colors.$FrenchPass;
  border-radius: 3px;
  min-height: 50px;
  margin: 2em;
  color: colors.$Boston_Blue;
  width: 73vw;

  .header {
    font-size: 16px;
    padding: 10px 20px;
    background-color: colors.$FrenchPass;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    text-align: center;
  }

  .back-chevron {
    font-weight: bold;
    display: flex;
    cursor: pointer;
  }

  .chevron-icon {
    font-size: 15px;
    margin-right: 10px;
  }

  .edit-icon {
    cursor: pointer;
    &:hover {
      color: colors.$carnation2;
    }
  }

  .save-button {
    background-color: colors.$Boston_Blue;
    border: none;
    font-weight: 800 !important;
    border-radius: 5px;
    text-shadow: none;
    color: colors.$white !important;
    font-family: "Quicksand";
    cursor: pointer;
    width: 80px;

    &:hover,
    &:active,
    &:focus {
      background-color: colors.$Crusta;
    }
  }

  .data_row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 15px;
    color: colors.$Boulder;
  }

  .label {
    font-size: 16px;
    color: colors.$black;
    margin-bottom: 5px;
  }
  .value {
    border: 1px solid colors.$BoulderLight;
    border-radius: 3px;
    font-size: 16px;
    padding: 5px 10px;
    color: colors.$Boulder;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
  }
}

.media_listing_section {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;

  .tablediv {
    flex: 1 10 auto;
  }

  .previewdiv {
    word-wrap: break-word;
  }
}

.preview_section {
  max-width: 380px;
  height: 100vh;
  overflow-y: auto;
  color: colors.$Boulder;
  .code_box {
    padding: 8px;
    border-radius: 3px;
    font-size: 12px;
  }

  .label {
    color: colors.$Crusta;
    font-weight: 800;
    font-style: 18px;
  }

  .media_id {
    font-size: 16px;
    border: 1px solid colors.$Silver;
    background-color: colors.$WildSand;
    text-align: center;
    padding: 6px;
    border-radius: 3px;
  }

  .code-block-thumbnail {
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    text-align: center;
    align-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 95%;
  }
}

.time_filter_row {
  display: flex;
  // justify-content: space-between;
  flex-direction: row;
  text-align: center;
  gap: 15px;
  margin-bottom: 15px;

  .filter_apply {
  // justify-content: center;
  // display: flex;
  background-color: colors.$Crusta;
  height: 35px;
  border-radius: 10px;
  // align-items: center;
  // margin-right: 20px;
  font-weight: 600;
  cursor: pointer;
  color: colors.$white;
  width: 100px;
  }
}
.filter_label {
  text-decoration: underline;
  font-weight: 800;
  font-size: 14px;
  cursor: pointer;
}

.search_div {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  .search_box {
    height: 30px;
    width: 230px;
    border-radius: 5px;
    border: 1px solid rgb(0 0 0 / 21%);
    padding: 5px;
  }
}

.additiona_form_fields {
  font-size: 25px;
  font-weight: 800;
  padding-left: 10px;
  cursor: pointer;

  .dropdown_icon {
    font-size: 16px;
    padding-left: 7px;
  }
}

.bill_row {
  display: flex;
  gap: 10px;
  padding: 8px;
  .bill_label {
    font-size: 16px;
    color: colors.$black;
    width: 30%;
    word-wrap: break-word;
  }
  .bill_value {
    font-size: 16px;
    color: colors.$black;
    width: 70%;
  }
}
.total {
  font-weight: 800;
}
.video_playback_section {
  display: flex;
  gap: 20px;
  flex-direction: row;
  min-height: 85vh;
  .video_carousel_div {
    padding-left: 30px;
    width: 580px;
    display: flex;
    flex-direction: column;
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: unset;
  font-size: unset;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}

.carousel_arrow {
  height: 30px;
  width: 50px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  height: 41px;
  width: 20px;
  top: 34%;
}

.ant-carousel .slick-next {
  right: -14px;
}

.ant-carousel .slick-prev {
  left: -28px;
}

.react-multiple-carousel__arrow--right {
  height: 41px !important;
  min-width: 20px !important;
  right: calc(0% + 1px) !important;
  background: transparent !important;
}

.react-multiple-carousel__arrow--left {
  height: 41px !important;
  min-width: 20px !important;
  left: calc(0% + 1px) !important;
  background: transparent !important;
}

.detail_page_first_row {
  display: flex;
  justify-content: flex-start;
  /* adjustment */
  position: relative;
  color: colors.$Crusta;
  font-weight: 800;
  font-size: 16px;
  margin-bottom: 25px;
  margin-top: 5px;
  align-items: center;

  .back_btn {
    flex: 0 1 auto;
    margin-right: auto;
    padding-left: 20px;
    cursor: pointer;
    font-size: 15px;
  }
  .back_icon {
    font-size: 12px;
  }

  .media_key {
    flex: 0 1 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.thumbnails_heading {
  color: colors.$Crusta;
  font-weight: 800;
  font-style: 18px;
  margin-bottom: 10px;
}

.subscriber_row {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #7973733d;
  margin-bottom: 20px;
}

.health_status_div {
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  font-size: 20px;
  margin-top: 10px;

  .healthy {
    color: green;
  }

  .faulty {
    color: red;
  }

  .loading {
    color: gray;
  }

  .label {
    flex: 0 1 auto;
    margin-left: 20px;
    color: colors.$Silver;
    font-weight: 800;
  }

  .status {
    flex: 0 1 auto;
    position: absolute;
    left: 50%;
  }
}

.videoJs-player {
  width: 500px !important;
}

.meeting-header {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
}

.peer_box {
  width: 200px;
  height: 150px;
  background-color: black;
  margin: 10px;
  display: inline-block;
}

.hide_box {
  display: none;
}

.section_box {
  // paddingTop="10px" paddingBottom="10px"
  padding-top: 10px;
  padding-bottom: 10px;
}

.subscriber_heading {
  color: colors.$Boston_Blue;
  font-weight: 800;
  font-size: 20px;
  padding-top: 1em;
  padding-left: 1.6em;
}

.loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;

  .loading-icon {
    font-size: 5em;
    color: colors.$Crusta;
  }
}

.audio_player {
  height: 250px;
}

.ep-edit-icon {
  cursor: pointer;
  color: colors.$Crusta;
  &:hover {
    color: colors.$Boston_Blue;
  }
}

.video-js {
  height: 350px;
}

.image-overlay {
  position: absolute; 
  z-index: 100;
  pointer-events: none;
  height: 350px;
}
