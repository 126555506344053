@import "antd/dist/antd.css";

$sidebar-bg-color: #fff;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #3088b8;
$highlight-color: #fe7737;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import "~react-pro-sidebar/dist/scss/styles.scss";

@font-face {
  font-family: "Quicksand";
  src: url("./fonts/Quicksand.ttf");
}

body {
  margin: 0;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
