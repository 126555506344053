// naming convention followed from here: https://chir.ag/projects/name-that-color/#CCCCCC

$Crusta: #fe7737;
$CrustaLight: #fe773752;
$Wax_Flower: #ffbe9d;
$Boston_Blue: #3088b8;
$black: #000000;
$white: #ffffff;
$Boulder: #7b7b7b;
$BoulderLight: #7b7b7b7a;
$dodgerBlue: #1890ff;
$carnation: #f4414f;
$carnation2: #f4424f;
$FrenchPass: #c7eafd;
$WildSand: #f5f5f5;
$Silver: #00000080;
