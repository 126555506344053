@use '../../styles/colors';

.outer-container {
  max-height: 87vh;
  display: flex;
  position: relative;
  gap: 10px;
}

.menu-wrap {
  border-radius: 15px !important;
  box-shadow: 0px 0px 6px 0px #00000040;
  // height: 87vh;
}

.aside_search_box {
  height: 36px;
  width: 95%;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0 / 21%);
  padding: 5px;
  margin: 3% 3% 0 2%;
}

.pro-sidebar {
  border-radius: 15px !important;
}

.content-wrap {
  box-shadow: 0px 0px 6px 0px #fe773752;
  border-radius: 15px;
  min-height: 87vh;
  width: 77%;
}
.hamburgerMenu {
  width: 256px;
  position: absolute;
  z-index: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 23px;
  height: 15px;
  left: 34px;
  top: 26px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: colors.$Crusta;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: colors.$Boston_Blue;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: colors.$white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-header {
  font-size: 15px;
}

.menu-item {
  font-size: 12px;
}

.sandbox-client {
  color: colors.$Boulder;

  .ant-menu-submenu-arrow {
    color: colors.$Boulder;
  }

  &:hover {
    color: colors.$Boulder;
  }
}

.menu-bar {
  widows: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: colors.$Boston_Blue;
}

.nav-right-section {
  display: flex;
  gap: 10px;
  align-items: center;
}
.region-dropdown {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: space-between;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.ant-select {
  color: colors.$Boston_Blue;
  padding-right: 20px;
}

.ant-select-selection-item {
  font-weight: bold !important;
}

.ant-select-arrow {
  right: 23px;
}

.nv-logo {
  padding-left: 20px;
  padding-bottom: 10px;
  cursor: pointer;
}

.logout-label {
  display: flex;
  background-color: colors.$Crusta;
  height: 27px;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  margin-right: 20px;
  font-weight: 600;
  padding-right: 15px;
  padding-left: 15px;
  cursor: pointer;
  color: colors.$white;
}
.logout-label:hover {
  background-color: colors.$Boston_Blue;
}

.ant-menu {
  width: 100% !important;
  color: colors.$Crusta;
  font-weight: 800;
}

.ant-menu-inline {
  border: none !important;
}

.ant-menu-submenu-title {
  padding-left: 0px !important;
}

.ant-menu-submenu-arrow {
  display: none !important;
}

.active-tab {
  color: colors.$Crusta;
}
