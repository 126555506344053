@use '../styles/colors';
.App {
  /* text-align: center; */
}
.dashboard-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  height: 100%;
  color: colors.$Boston_Blue;
  background-color: colors.$CrustaLight;
  border-radius: 10px;

  .construction-icon {
    color: colors.$Crusta;
    font-size: 45px;
  }
}

.page_loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ant-spin-dot-item {
  background-color: colors.$Crusta;
}

.cost_table_section {
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;

  .table_head {
    font-size: 20px;
    font-weight: 800;
    margin: 1em;
  }
}

.otpContainer {
  margin: 5% auto;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.join_meeting_box {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .meeting_session {
    background-color: black;
    color: white;
  }
}

.meeting_controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.streaming_video_section {
  display: flex;
  justify-content: center;
  // background-color: black;
  // color: white;
}

.call_end_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.join_call_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
  .join_form {
    display: flex;
    flex-direction: column;
  }
}

.meet_btn {
  margin: 10px;
  width: 300px;
}

.loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;

  .loading-icon {
    font-size: 5em;
    color: colors.$Crusta;
  }
}
